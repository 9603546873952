<template>
  <div>
    <AppBar />

    <v-row
      justify="center"
      no-gutters>
      <h1 class="offBlack--text text-uppercase text-center">Create a new password</h1>
    </v-row>

    <v-form
      @submit.prevent="sent = true"
      class="px-6">
      <v-row no-gutters>
        <v-text-field
          label="Create a password (min 8 chars)"
          @click:append="showPassword = !showPassword"
          :type="showPassword ? 'text' : 'password'"
          hint="At least 8 characters"
          color="primaryColor"
          v-model="newPassword"
          class="my-4 px-4">
          <template v-slot:append>
            <v-img
              height="40"
              width="40"
              :src="showPassword ? eye : eyeOff" />
          </template>
        </v-text-field>
      </v-row>

      <v-row
        justify="center"
        no-gutters>
        <v-col cols="10">
          <v-btn
            type="submit"
            class="brand-gradient"
            :loading="isCreatingANewPassword"
            :disabled="isCreatingANewPassword"
            dark
            rounded
            block
            large
            depressed
            >Save new password</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <!-- <div
      v-if="sent"
      class="reset-password-email-success mt-10 px-6"
    >
      Password reset email sent. Please check your email and click the link.
    </div> -->
  </div>
</template>

<script>
  import PasswordResetMixin from '@/views/auth/password-reset/PasswordReset.mixin.vue';
  export default {
    mixins: [PasswordResetMixin],
  };
</script>

<style lang="css" scoped>
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.16em;
  }
</style>
